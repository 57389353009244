import { ModifyLinkInBioDto } from '@link-in-bio/domain';
import { PlaygroundAction, PlaygroundActionType } from '../types/playground-action.type';
import { PlaygroundMode } from '../enums/mode.enum';
import { merge } from 'lodash';

export type PlaygroundModeType = PlaygroundMode.EDITOR | PlaygroundMode.PREVIEW;

export type PlaygroundState = {
  mode: PlaygroundMode;
  payload: ModifyLinkInBioDto;
  actions: string[];
  isLinkDraggable: boolean;
};

export const initialState: PlaygroundState = {
  mode: PlaygroundMode.PREVIEW,
  payload: {
    _id: '',
    username: '',
    name: '',
    title: '',
    bio: '',
    cta_button: {
      label: '',
      url: '',
    },
    links: [],
    mailing_integrations: [],
  },
  actions: [],
  isLinkDraggable: true,
};

export const playgroundReducer = (state: PlaygroundState, action: PlaygroundAction): PlaygroundState => {
  switch (action.type) {
    case PlaygroundActionType.SET_INIT_PAYLOAD:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
      };
    case PlaygroundActionType.SET_PAYLOAD:
      return {
        ...state,
        payload: merge({}, state.payload, action.payload),
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.CLEAR_PAYLOAD:
      return {
        ...state,
        payload: initialState.payload,
      };
    case PlaygroundActionType.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case PlaygroundActionType.SET_LINK: {
      const currentLinks = state.payload.links || [];
      const emptyLinkIndex = currentLinks.findIndex((link) => link.url === '');
      let updatedLinks;

      if (emptyLinkIndex !== -1) {
        updatedLinks = currentLinks.map((link, index) =>
          index === emptyLinkIndex ? { ...link, ...action.payload } : link,
        );
      } else {
        const newPosition =
          currentLinks.length > 0 ? Math.max(...currentLinks.map((link) => link.position ?? 0)) + 1 : 0;
        updatedLinks = [{ ...action.payload, position: newPosition }, ...currentLinks];
      }

      return {
        ...state,
        payload: {
          ...state.payload,
          links: updatedLinks,
        },
        actions: [...state.actions, action.type],
      };
    }
    case PlaygroundActionType.SET_LINK_PAYLOAD:
      return {
        ...state,
        payload: {
          ...state.payload,
          links: state.payload.links?.map((link) => {
            if ((link._id || link.client_id) === action.payload.linkId) {
              const [_linkIndex, fieldName] = Object.entries(action.payload)[0][0].split('.').slice(1);
              const fieldValue = Object.entries(action.payload)[0][1];

              return {
                ...link,
                [fieldName]: fieldValue,
              };
            }
            return link;
          }),
        },
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.DELETE_LINK:
      return {
        ...state,
        payload: {
          ...state.payload,
          links: state.payload.links?.filter((link) => (link._id || link.client_id) !== action.payload),
        },
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.REORDER_LINKS:
      return {
        ...state,
        payload: {
          ...state.payload,
          links: action.payload.map((link, index) => ({
            ...link,
            position: action.payload.length - 1 - index,
          })),
        },
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.CLEAR_ACTIONS:
      return {
        ...state,
        actions: [],
      };
    case PlaygroundActionType.SET_IS_LINK_DRAGGABLE:
      return {
        ...state,
        isLinkDraggable: action.payload,
      };
    default:
      return state;
  }
};
