import { ModifyLinkDto, ModifyLinkInBioDto } from '@link-in-bio/domain';
import { PlaygroundModeType } from '../store/playground-reducer';

export enum PlaygroundActionType {
  SET_INIT_PAYLOAD = 'SET_INIT_PAYLOAD',
  SET_PAYLOAD = 'SET_PAYLOAD',
  CLEAR_PAYLOAD = 'CLEAR_PAYLOAD',
  CHANGE_MODE = 'CHANGE_MODE',
  SET_LINK = 'SET_LINK',
  SET_LINK_PAYLOAD = 'SET_LINK_PAYLOAD',
  DELETE_LINK = 'DELETE_LINK',
  REORDER_LINKS = 'REORDER_LINKS',
  SET_IS_LINK_DRAGGABLE = 'SET_IS_LINK_DRAGGABLE',
  CLEAR_ACTIONS = 'CLEAR_ACTIONS',
}

export interface ISetInitPayloadAction {
  type: PlaygroundActionType.SET_INIT_PAYLOAD;
  payload: ModifyLinkInBioDto;
}

export interface ISetPayloadAction {
  type: PlaygroundActionType.SET_PAYLOAD;
  payload: ModifyLinkInBioDto;
}

export interface IClearPayloadAction {
  type: PlaygroundActionType.CLEAR_PAYLOAD;
}

export interface IChangeModeAction {
  type: PlaygroundActionType.CHANGE_MODE;
  payload: PlaygroundModeType;
}

export interface ISetLinkAction {
  type: PlaygroundActionType.SET_LINK;
  payload: ModifyLinkDto;
}

export interface ISetLinkPayloadAction {
  type: PlaygroundActionType.SET_LINK_PAYLOAD;
  payload: ModifyLinkDto & {
    linkId: string;
  };
}

export interface IDeleteLinkAction {
  type: PlaygroundActionType.DELETE_LINK;
  payload: string;
}

export interface IReorderLinksAction {
  type: PlaygroundActionType.REORDER_LINKS;
  payload: ModifyLinkDto[];
}

export interface ISetIsLinkDraggableAction {
  type: PlaygroundActionType.SET_IS_LINK_DRAGGABLE;
  payload: boolean;
}

export interface IClearActionsAction {
  type: PlaygroundActionType.CLEAR_ACTIONS;
}

export type PlaygroundAction =
  | ISetInitPayloadAction
  | ISetPayloadAction
  | IClearPayloadAction
  | IChangeModeAction
  | ISetLinkAction
  | ISetLinkPayloadAction
  | IDeleteLinkAction
  | IReorderLinksAction
  | ISetIsLinkDraggableAction
  | IClearActionsAction;

export type PlaygroundDispatch = (action: PlaygroundAction) => void;
